import React from "react";
import { Link, graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Container, Row, Col } from "reactstrap";

import Layout from "./layout";
import SEO from "./seo";

const shortcodes = { Link };

const BlogPostLayout = ({ data: { mdx } }) => {
    return (
        <Layout>
            <SEO title={mdx.frontmatter.title} />

            <Container style={{ paddingTop: 200 }} className="pb-5 mb-5">
                <Row>
                    <Col>
                        <article>
                            <header className="pb-5">
                                <h1 className="text-primary text-uppercase">
                                    {mdx.frontmatter.title}
                                </h1>
                                <h2 className="text-secondary">
                                    {mdx.frontmatter.subtitle}
                                </h2>
                                <div className="d-flex justify-content-between text-muted">
                                    <time>{new Date(mdx.frontmatter.date).toLocaleDateString(undefined, { weekday: "long", year: "numeric", month: "long", day: "numeric" })}</time>
                                    <span>{mdx.frontmatter.author}</span>
                                </div>
                            </header>

                            <MDXProvider components={shortcodes}>
                                <MDXRenderer>{mdx.body}</MDXRenderer>
                            </MDXProvider>
                        </article>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
          title
          subtitle
          author
          date
      }
    }
  }
`

export default BlogPostLayout;
